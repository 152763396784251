import React, { useCallback, useEffect, useState } from "react";
import { Convert, PatientStatistics } from "../types/PatientStats";
import { endpoints, replaceParams } from "@utils/axios";
import { Conversion, AppointmentStatistics } from "../types/AppointmentStats";
import AppointmentDashboardCards from "./AppointmentDashboardCards";
import moment from "moment";
import { Appointments, AppointmentConvert } from "../types/Appointments";
import useAxios from "@routes/hooks/use-axios";
import CountCard from "@components/count-card/count-card";
import Button, { ButtonVariant } from "@components/button/outline-button";

import WalkinAppointmentPage from "@pages/appointments/walkin-appointment/WalkinAppointmentPage";
import BookAppointmentPage from "@pages/appointments/book-appointment/BookAppointmentPage";
import { QuickPatientAddDialog } from "@pages/appointments/book-appointment/QuickPatientAddDialog";
import { useNavigate } from "react-router-dom";
import { paths } from "@routes/paths";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Tab } from "@headlessui/react";
import { EntityBranch, EntityBranchConvert } from "../types/EntityBranch";
import { CurrencyRupeeIcon, SquaresPlusIcon } from "@heroicons/react/24/solid";

const AssistantDashboard = () => {
  const [patientStat, setPatientStats] =
    React.useState<PatientStatistics | null>(null);
  const [AppointmentStat, setAppointmentStats] =
    React.useState<AppointmentStatistics | null>(null);
  const axios = useAxios();

  const [scheduledAppointmentList, setScheduledAppointmentList] =
    React.useState<Appointments[]>([]);
  const [waitingAppointmentList, setWaitingAppointmentList] = React.useState<
    Appointments[]
  >([]);
  const [ongoingAppointmentList, setOngoingAppointmentList] = React.useState<
    Appointments[]
  >([]);
  const [completedAppointmentList, setCompletedAppointmentList] =
    React.useState<Appointments[]>([]);
  const [quickPatientAddDialog, setQuickPatientAddDialog] =
    React.useState(false);
  const userData = getUserPersistedOnLocalStorage();
  const [branches, setBranches] = React.useState<EntityBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = React.useState<string>("0");

  const [dateSwitcher, setDateSwitcher] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );

  const naviagtor = useNavigate();

  const navigateBilling = () => {
    naviagtor(paths.billing.index.route, {});
  };

  const getScheduledAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Scheduled",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setScheduledAppointmentList(toSet);
  };

  const getScheduledAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;
    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Scheduled",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setScheduledAppointmentList(toSet);
  };

  const getWaitingAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Waiting",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setWaitingAppointmentList(toSet);
  };

  const getWaitingAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Waiting",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setWaitingAppointmentList(toSet);
  };

  const getOngoingAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Ongoing",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setOngoingAppointmentList(toSet);
  };

  const getOngoingAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Ongoing",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setOngoingAppointmentList(toSet);
  };

  const getCompletedAppointmentList = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Completed",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setCompletedAppointmentList(toSet);
  };

  const getCompletedAppointmentListForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.appointmentListByStatus,
      paramsMap
    );

    const response = await axios.get(filteredEndpoint, {
      params: {
        date: dateSwitcher,
        status: "Completed",
      },
    });
    const data: Appointments[] = response.data["data"];
    const toSet: Appointments[] = [];
    if (data != null)
      for (const appointment of data) {
        const converted = AppointmentConvert.toAppointments(
          JSON.stringify(appointment)
        );
        let duration =
          appointment.endTimeMinutes - appointment.startTimeMinutes;

        converted.apptdate = new Date(
          moment(converted.apptdate).toLocaleString()
        );

        converted.startTime = moment(converted.apptdate).format("hh:mm A");
        converted.endTime = moment(appointment.startTime, "hh:mm A")
          .add(duration, "minutes")
          .format("hh:mm A");
        converted.startTimeMinutes =
          converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
        converted.endTimeMinutes =
          converted.apptdate.getHours() * 60 +
          converted.apptdate.getMinutes() +
          duration;
        toSet.push(converted);
      }
    setCompletedAppointmentList(toSet);
  };

  const getPatientStatus = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.patientsCount,
      paramsMap
    );
    try {
      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Convert.toPatientStatistics(JSON.stringify(data));
      const patientStats = converted;
      setPatientStats(patientStats);
    } catch (error) {
      console.error(error);
    }
  };

  const getPatientStatusForEntityOwner = async () => {
    let paramsMap: Map<string, string>;

    paramsMap = new Map<string, string>([["id", selectedBranch]]);

    const filteredEndpoint = replaceParams(
      endpoints.assistantDashboard.patientsCount,
      paramsMap
    );
    try {
      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Convert.toPatientStatistics(JSON.stringify(data));
      const patientStats = converted;
      setPatientStats(patientStats);
    } catch (error) {
      console.error(error);
    }
  };

  const getAppointmentStatus = async () => {
    try {
      let paramsMap: Map<string, string>;

      paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.appointmentsCount,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Conversion.toAppointmentStatistics(
        JSON.stringify(data)
      );
      const appointmentStats = converted;
      setAppointmentStats(appointmentStats);
    } catch (error) {}
  };

  const getAppointmentStatusForEntityOwner = async () => {
    try {
      let paramsMap: Map<string, string>;

      paramsMap = new Map<string, string>([["id", selectedBranch]]);

      const filteredEndpoint = replaceParams(
        endpoints.assistantDashboard.appointmentsCount,
        paramsMap
      );

      const response = await axios.get(filteredEndpoint, {
        params: {
          requestedDate: dateSwitcher,
        },
      });
      const data = response.data["data"];

      const converted = Conversion.toAppointmentStatistics(
        JSON.stringify(data)
      );
      const appointmentStats = converted;
      setAppointmentStats(appointmentStats);
    } catch (error) {}
  };


  const checkIn = () => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getScheduledAppointmentListForEntityOwner();
      getWaitingAppointmentListForEntityOwner();
    } else {
      getScheduledAppointmentList();
      getWaitingAppointmentList();
    }
  };

  const ongoing = () => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getWaitingAppointmentListForEntityOwner();
      getOngoingAppointmentListForEntityOwner();
    } else {
      getWaitingAppointmentList();
      getOngoingAppointmentList();
    }
  };

  const completed = () => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getOngoingAppointmentListForEntityOwner();
      getCompletedAppointmentListForEntityOwner();
      getAppointmentStatusForEntityOwner();
    } else {
      getOngoingAppointmentList();
      getCompletedAppointmentList();
      getAppointmentStatus();
    }
  };

  const callBackCompleted = () => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getCompletedAppointmentListForEntityOwner();
    } else {
      getCompletedAppointmentList();
    }
  };

  // below is the api call to get the appointmentstatus and patient status in a react hook
  useEffect(() => {
    if (userData?.user_role === "ENTITY_OWNER") {
      getAppointmentStatusForEntityOwner();
      getPatientStatusForEntityOwner();
      getScheduledAppointmentListForEntityOwner();
      getWaitingAppointmentListForEntityOwner();
      getOngoingAppointmentListForEntityOwner();
      getCompletedAppointmentListForEntityOwner();
    } else {
      getAppointmentStatus();
      getPatientStatus();
      getScheduledAppointmentList();
      getWaitingAppointmentList();
      getOngoingAppointmentList();
      getCompletedAppointmentList();
    }
  }, [dateSwitcher, selectedBranch]);

  const appointmentData = AppointmentStat;
  const [bookAppointmentDialog, setBookAppointmentDialog] = useState(false);
  const [walkinAppointmentDialog, setWalkinAppointmentDialog] = useState(false);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const getBranches = useCallback(async () => {
    const paramsMap = new Map<string, string>([
      ["entityId", userData?.entity_id ?? ""],
    ]);
    const filteredEndpoint = replaceParams(
      endpoints.ownerDashboard.branches,
      paramsMap
    );
    const response = await axios.get(filteredEndpoint);
    const data = response.data["data"];
    const converted = [];

    for (const branch of data) {
      const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
      converted.push(temp);
    }

    setBranches(converted);
  }, [axios]);

  useEffect(() => {
    getBranches();
  }, []);

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row justify-end mb-2">
        <div className="flex flex-row">
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={() => setBookAppointmentDialog(true)}
            className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal"
          >
            <SquaresPlusIcon className="w-5 h-5 text-primary-500" />
            <span className="pl-1 text-sm"> Book Appointment </span>
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={navigateBilling}
            className="mr-0 sm:mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal"
          >
            <CurrencyRupeeIcon className="w-5 h-5 text-secondary-500" />
            <span className="pl-1 text-sm"> Billing </span>
          </Button>
          {/* <Button
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={() => setWalkinAppointmentDialog(true)}
            children="Walkin Appointment"
            className="mr-0 sm:mr-2 w-full sm:w-fit justify-center sm:justify-normal"
          /> */}
        </div>
        <div className="flex flex-row mt-2 sm:mt-0">
          {userData?.user_role === "ENTITY_OWNER" ? (
            <div className="w-full">
              <select
                className="w-full sm:w-44  h-full border border-1 rounded-md cursor-pointer"
                disabled={false}
                onChange={handleSelectChange}
              >
                <option key={"All"} value={"0"}>
                  All Branches
                </option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <h5 className="text-l font-bold text-secondary-600">
          Appointment Statistics
        </h5>
        <hr className="my-2 "></hr>
        <div className="grid grid-cols-2 gap-x-2 md:grid-cols-4 lg:grid-cols-4">
          <CountCard
            number={appointmentData?.appointmentTotalCount}
            text={"Total"}
            textColor="text-secondary-500"
          ></CountCard>
          <CountCard
            number={appointmentData?.appointmentCompletedCount}
            text={"Completed"}
            textColor="text-tertiary-500"
          ></CountCard>
          <CountCard
            number={appointmentData?.appointmentCancelledCount}
            text={"Cancelled"}
            textColor="text-error-500"
          ></CountCard>
          {/*           <CountCard
            number={appointmentData?.appointmentMissedCount}
            text={'No Show'}
            textColor="text-black-500"
          ></CountCard>
          <CountCard
            number={patientData?.newClientCount}
            text={'New Appointments'}
            textColor="text-primary-500"
          ></CountCard>
          <CountCard
            number={patientData?.followUpClientCount}
            text={'Follow Ups'}
            textColor="text-secondary-500"
          ></CountCard> */}
        </div>
        <div className="flex flex-col md:flex-row w-full md:justify-between md:items-center">
          <div className="flex-shrink-0 font-bold text-lg">
            {dateSwitcher === moment(new Date()).format("DD-MM-YYYY")
              ? "Today"
              : "Tomorrow"}
          </div>

          <div className="flex-shrink-0">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl lg:ml-2">
                <Tab
                  key={"Today"}
                  onClick={() => {
                    setDateSwitcher(moment(new Date()).format("DD-MM-YYYY"));
                  }}
                  className={({ selected }) =>
                    classNames(
                      "w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                      selected
                        ? "bg-white text-primary-700 shadow border-primary-500"
                        : "text-primary-300"
                    )
                  }
                >
                  Today
                </Tab>
                <Tab
                  key={"Tomorrow"}
                  onClick={() => {
                    const currentDate = moment(dateSwitcher, "DD-MM-YYYY");

                    const nextDate = currentDate.add(1, "days");

                    setDateSwitcher(nextDate.format("DD-MM-YYYY"));
                  }}
                  className={({ selected }) =>
                    classNames(
                      "w-full px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                      selected
                        ? "bg-white text-primary-700 shadow border-primary-500"
                        : "text-primary-300"
                    )
                  }
                >
                  Tomorrow
                </Tab>
              </Tab.List>
            </Tab.Group>
          </div>
        </div>

        <div className="mt-2 px-2 pt-1 w-full flex flex-row justify-end">
          <span className="text-gray-400">
            **Click on the card to see Patient information
          </span>
        </div>
        <div className="my-2 grid grid-cols-1 gap-2  md:my-5 md:grid-cols-2 md:gap-5 lg:grid-cols-2 xl:grid-cols-4">
          <AppointmentDashboardCards
            appointmentStatus="Scheduled"
            buttonName="Check-in"
            appointmentList={scheduledAppointmentList}
            callback={checkIn}
            dateSwitcher={dateSwitcher}
          />
          <AppointmentDashboardCards
            appointmentStatus="Waiting"
            buttonName="Start Session"
            appointmentList={waitingAppointmentList}
            callback={ongoing}
            dateSwitcher={dateSwitcher}
          />
          <AppointmentDashboardCards
            appointmentStatus="Ongoing"
            buttonName="Mark Complete"
            appointmentList={ongoingAppointmentList}
            callback={completed}
            dateSwitcher={dateSwitcher}
          />
          <AppointmentDashboardCards
            appointmentStatus="Completed"
            buttonName="Bill Details"
            appointmentList={completedAppointmentList}
            callback={callBackCompleted}
            dateSwitcher={dateSwitcher}
          />
        </div>
      </div>
      {bookAppointmentDialog && (
        <BookAppointmentPage
          setBookAppointmentDialog={setBookAppointmentDialog}
          setQuickPatientAddDialog={setQuickPatientAddDialog}
          getAppointments={
            userData?.user_role === "ENTITY_OWNER"
              ? getScheduledAppointmentListForEntityOwner
              : getScheduledAppointmentList
          }
        />
      )}

      {walkinAppointmentDialog && (
        <WalkinAppointmentPage
          setWalkinAppointmentDialog={setWalkinAppointmentDialog}
          setQuickPatientAddDialog={setQuickPatientAddDialog}
          getAppointments={
            userData?.user_role === "ENTITY_OWNER"
              ? getScheduledAppointmentListForEntityOwner
              : getScheduledAppointmentList
          }
        />
      )}

      {quickPatientAddDialog && (
        <QuickPatientAddDialog
          setQuickPatientAddDialog={setQuickPatientAddDialog}
        />
      )}
    </div>
  );
};

export default AssistantDashboard;
