import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AuthGuard } from "@authentication/guard";
import LoadingScreen from "@components/loading";
import logo from "./../../../public/logo.png";
import { useAuthContext } from "@authentication/hooks/use-auth-context";
import navigation from "./nav-items";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { usePathname } from "@routes/hooks";
import {
  getCurrentRouteMetadata,
  getPageTitleByPath,
  isRouteAccessible,
  paths,
} from "@routes/paths";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import createInitials from "@utils/createInitials";
import SetupGuard from "@authentication/guard/setup-guard";
import { STORAGE_KEYS } from "@constants/storage-keys";
import tzmoment from "moment-timezone";
import { Breadcrumb } from "./breadcrumb";
import DashboardNavigation from "./dashboardNavigation";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type InitialData = {
  EntityBranchName?: string;
  EntityName?: string;
  isEntityDetailsCompleted: boolean;
  isTherapiesConfigurationCompleted: boolean;
  entityTimeZone: string;
  isWorkingHoursConfigurationCompleted: boolean;
  isConsentCompleted: boolean;
  isEntityWorkingHoursConfigCompleted: boolean;
  isEntityBranchWorkingHoursConfigCompleted: boolean;
  RoleName?: string;
  entityLogoUrl?: string;
  entityLogoId?: string;
  uiVersion?: string;
};

// Converts JSON strings to/from your types
export class Convert {
  public static toInitialData(json: string): InitialData {
    return JSON.parse(json);
  }

  public static initialDataToJson(value: InitialData): string {
    return JSON.stringify(value);
  }
}

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [initialData, setInitialData] = useState<InitialData>();
  const [initials, setInitials] = useState<string>("");

  const activeTabColorClasses: string = "bg-primary-100 text-primary-500";
  const inActiveTabColorClasses: string =
    "text-gray-700 hover:text-primary-600 hover:bg-gray-50";

  const { logout } = useAuthContext();
  const userData = getUserPersistedOnLocalStorage();
  let [isOpen, setIsOpen] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  const currentPath = usePathname();
  const naviagtor = useNavigate();

  const userNavigation = [
    {
      name: "Your profile",
      callBack: () => {
        navigateViewStaff();
      },
    },
    { name: "Sign out", callBack: logout },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  const getEntityData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["userid", userData?.user_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.dashboardInitialData.initialData,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data != null) {
        const convertedData = Convert.toInitialData(JSON.stringify(data));
        const initials = createInitials(convertedData.EntityName ?? "");
        setInitials(initials);
        setInitialData(convertedData);
        sessionStorage.setItem(
          STORAGE_KEYS.ENTITY_TIMEZONE,
          convertedData.entityTimeZone
        );
        sessionStorage.setItem(
          STORAGE_KEYS.UI_VERSION,
          convertedData.uiVersion ?? ""
        );
      }
    } catch (error) {
      // toast.error(error.message);

      console.error("Error fetching staff data:", error);
    }
  };

  useEffect(() => {
    if (userData !== null) getEntityData();

    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const navigateViewStaff = () => {
    const paramsMap = new Map<string, string>([
      ["staffId", userData?.user_id ?? ""],
    ]);
    closeModal();
    naviagtor(replaceParams(paths.staff.profileView.route, paramsMap));
  };
  const navigateStaffEntityManage = () => {
    const paramsMap = new Map<string, string>([
      ["userId", userData?.user_id ?? ""],
      ["entityId", userData?.entity_id ?? ""],
      ["entityName", initialData?.EntityName ?? ""],
    ]);
    const queryParams = new Map<string, string>([
      ["name", initialData?.EntityName ?? ""],
    ]);
    closeModal();
    naviagtor(
      replaceParams(paths.staff.entityManage.route, paramsMap, queryParams)
    );
  };
  const navigateStaffBranchManage = () => {
    const paramsMap = new Map<string, string>([
      ["userId", userData?.user_id ?? ""],
      ["branchId", userData?.branch_id ?? ""],
      ["branchName", initialData?.EntityBranchName ?? ""],
    ]);
    const queryParams = new Map<string, string>([
      ["name", initialData?.EntityBranchName ?? ""],
    ]);
    closeModal();
    naviagtor(
      replaceParams(paths.staff.branchManage.route, paramsMap, queryParams)
    );
  };

  function formatRoleName(role: string): string {
    return role
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0) + word.slice(1))
      .join(" ");
  }

  const updateScreenSize = () => {
    setIsLargeScreen(window.innerWidth >= 1024);
  };

  return (
    <>
      <AuthGuard>
        <SetupGuard value={initialData}>
          <Suspense fallback={<LoadingScreen />}>
            <>
              <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-50 xl:hidden"
                  onClose={setSidebarOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-900/80" />
                  </Transition.Child>

                  <div className="fixed inset-0 flex">
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="-translate-x-full"
                      enterTo="translate-x-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-x-0"
                      leaveTo="-translate-x-full"
                    >
                      <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-in-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                            <button
                              type="button"
                              className="-m-2.5 p-2.5"
                              onClick={() => setSidebarOpen(false)}
                            >
                              <span className="sr-only">Close sidebar</span>
                              <XMarkIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </Transition.Child>
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <DashboardNavigation
                          initialData={initialData ?? {}}
                          logo={logo}
                          navigation={navigation}
                          isRouteAccessible={isRouteAccessible}
                          activeTabColorClasses={activeTabColorClasses}
                          inActiveTabColorClasses={inActiveTabColorClasses}
                          initials={initials}
                          formatRoleName={formatRoleName}
                          navigateStaffEntityManage={navigateStaffEntityManage}
                          navigateStaffBranchManage={navigateStaffBranchManage}
                          onClick={() => setSidebarOpen(false)}
                        />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>

              {/* Static sidebar for desktop */}
              <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <DashboardNavigation
                  initialData={initialData ?? {}}
                  logo={logo}
                  navigation={navigation}
                  isRouteAccessible={isRouteAccessible}
                  activeTabColorClasses={activeTabColorClasses}
                  inActiveTabColorClasses={inActiveTabColorClasses}
                  initials={initials}
                  formatRoleName={formatRoleName}
                  navigateStaffEntityManage={navigateStaffEntityManage}
                  navigateStaffBranchManage={navigateStaffBranchManage}
                />
              </div>

              <div className="xl:pl-72">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Separator */}
                  <div
                    className="h-6 w-px bg-gray-200 lg:hidden"
                    aria-hidden="true"
                  />

                  <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div className="relative flex flex-1">
                      <h1 className="flex items-center text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">
                        {isLargeScreen && <Breadcrumb></Breadcrumb>}
                        {!isLargeScreen && (
                          <>
                            {getCurrentRouteMetadata(currentPath.toString())
                              .title ?? "Gritup"}
                          </>
                        )}
                      </h1>
                    </div>
                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                      <span
                        className="hidden md:hidden lg:flex lg:items-center sm:inline-block ml-4 text-base leading-6 text-gray-600"
                        aria-hidden="true"
                      >
                        {tzmoment.tz.guess()} (UTC
                        {tzmoment.tz(tzmoment.tz.guess()).format("Z")})
                      </span>
                      {/* Separator */}
                      <div
                        className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                        aria-hidden="true"
                      />

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                          <span className="sr-only">Open user menu</span>
                          {/* <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        /> */}
                          <span className="lg:flex lg:items-center">
                            <span
                              className="hidden lg:block ml-4 text-sm font-semibold leading-6 text-gray-900"
                              aria-hidden="true"
                            >
                              {userData?.name ?? ""}
                            </span>
                            <ChevronDownIcon
                              className="ml-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-60 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <div className="mb-1">
                              <Menu.Item key={"Current Timezone"}>
                                <span
                                  className={
                                    "lg:hidden px-3 py-1 text-xs leading-6 text-gray-600"
                                  }
                                >
                                  {tzmoment.tz.guess()} (UTC
                                  {tzmoment.tz(tzmoment.tz.guess()).format("Z")}
                                  )
                                </span>
                              </Menu.Item>
                            </div>
                            <div className="mb-1">
                              <Menu.Item key={"Username"}>
                                <span
                                  className={
                                    "lg:hidden px-3 py-1 text-base leading-6 text-gray-900"
                                  }
                                >
                                  {userData?.name ?? ""}
                                </span>
                              </Menu.Item>
                            </div>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <span
                                    onClick={item.callBack}
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-base leading-6 text-gray-900"
                                    )}
                                  >
                                    {item.name}
                                  </span>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <main className="py-4 h-full w-full">
                  <div className="px-4 sm:px-6 lg:px-8 h-full w-full">
                    <Outlet />
                  </div>
                </main>
              </div>
            </>
          </Suspense>
        </SetupGuard>
      </AuthGuard>
    </>
  );
}
